
.card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
  }
  .CiCirclePlusoption svg{
    font-size: 20px;
    margin-right: 5px;
  }
  .card {
    border: 1px solid #ccc;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .save-comm{
    border: none;
    margin-left: 5px;
    background-color: #94d333;
    color: white;
    border-radius: 4px;
  }
  .commission-container{
    display: flex;
    align-items: center;
  }
  .commission-container p{
    margin: 0;
  }
  .notset-com{
    background-color: rgb(206, 41, 26);
    border: transparent;
    color: white;
    padding: 4px;
    border-radius: 4px;
font-size: 12px;
  }
  .commission-container svg{
    margin-left:5px ;
    color: #48bc18;
    font-size: 20px;
    cursor: pointer;
  }

  .view-menu{
    color: #c64141;
    font-weight: bolder;
    cursor: pointer;
  }
  .view-menu span{
    font-size: 12px;
  }
  .card img {
    max-width: 100%;
    width: 120px;
  }
  .rest-image-last img{
    height: 40px !important;
  }
  .card-1 {
    box-shadow: 0 1px 0px rgba(232, 231, 236, 0.5);
}
  .card-1 img {
    max-width: 100%;
    width: 120px;
  }
  .paginate-class .pagination{
    gap: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    overflow: scroll;
  }
  .card .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
  }
  .user-info-flex{
    display: flex;
    margin-top: 25px;
    align-items: center;
  }
  .card .user-info img {
    border-radius: 50%;
    margin-right: 10px;
  }
  .strong-item-margin{
    margin: 0;
    padding: 0;
    margin-right: 5px !important;
  }
  .remove-circleicon{
    color: #b73c3c;
    font-size: 25px;
    cursor: pointer;
  }
  .tabs-container .tabscards{
    border: none;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #c9c9c9 !important;
  }
.tabs-container{
  margin-bottom: 15px;
}
.viewed-notification {
  background-color: #ffffff; /* Set your desired background color for viewed notifications */
}

.unviewed-notification {
  background-color: #c5def5;; /* Set your desired background color for unviewed notifications */
}


.delete-ittem svg{
  font-size: 20px;
    margin-right: 6px;
}
.initials-container, .user-pro img {
  display: inline-block;
  width: 40px; 
  height: 40px; 
  border-radius: 50%;
  background-color: #e0e0e0;
  text-align: center;
  line-height: 40px;
}

  .tabs-container .active-tab{
    border: none;
    height: 40px;
    background-color: #82bc43 !important;
    border-radius: 5px;
    padding: 5px 10px;
    color: white;
    margin-right: 10px;
  }
  .card .address {
    margin-bottom: 15px;
  }
  .paginate-class .prev-class a, .paginate-class .next-class a{
    border: none;
    font-size: 20px;
  }
  .card .delivered-message {
    margin-bottom: 15px;
    color: #2dc58c;
    background-color: #e6f8f1 !important;
    border-color: transparent;
    padding: 5px;
    border-radius: 6px;
  }
  .card .cancelled-message {
    margin-bottom: 15px;
    color: #c54b2d;
    background-color: #f8e9e6 !important;
    border-color: transparent;
    padding: 5px;
    border-radius: 6px;
  }
  .card .green-dot, .order-card .green-dot {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    box-shadow: 0 0 5px green; 
  }
  .order-card .cardh5{
    font-size: 14px;
    margin-top: 3px;
  }
  .card-p-flex{
    display: flex;
  }
  .order-card .cardp{
    font-size: 12px;
    margin: 0;
    width: 130px;
  }
  .restaurant-det{
    width: 100%;
    margin-left: 10px;
  }
  .restaurant-det .rest-name{
    margin-bottom: 0;
.order-class{
    margin: 0 20px;
}
  }
  .restaurant-det .user-name{
    font-weight: bolder;
  }
  .card .dot, .order-card .dot {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    box-shadow: 0 0 5px red; 
  }
  .restaurant-adddress{
color: #6da162;
  }
  .user-info{
    position: relative;
  }
  .delivered-msg{
    position: absolute;
    top: -8px;
    right: 0;
}
  .rest-name svg{
    margin-right: 4px;
    color:#e04c4c;
  }
  .card .order-details {
    border-top: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
    margin: 15px 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }
  
  .card .order-details .order-date,
  .card .order-details .price {
    display: inline-block;
    margin-right: 15px;
  }
  
  .restauarant-details-flex{
    max-width: 700px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .seller-details-container{
    max-width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: fit-content;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .address {
    font-weight: bolder;
    color: #555;
  }
  
  p {
    margin: 8px 0;
    color: #666;
  }
  .modal-title ._5ipl{
    width: 100px;
    margin-left: 5px;
    border: 1px solid #f9c623;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .status-open {
    color: #4caf50;
    font-weight: bolder;
  }
  
  .status-closed {
    color: #f44336; 
    font-weight: bolder;
  }
  .category-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .category-box ._5lxb{
    margin: 0;
  }
  .restaurant-details-container{
    display: flex;
    gap: 20px;
  }
  .category-item{

      border: 1px solid #f9c623;
      border-radius: 5px;
      padding: 5px 10px;
      margin: 5px;
    width: fit-content;
  }
  .category-item1{
border: none;
    background-color: #f9c623 !important;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
  width: fit-content;
  }
  .open-clos{
    display: flex;
    justify-content: space-between;
  }
  .seller-details p{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .restaurant-image {
    width: 100px;
    border-radius: 8px;
  }
  .rest-det-flex {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 10px;
  }

  // <=============>
  // .bg-green{
  //   background-color:#82bc43 !important;
  // }
  .nk-sidebar-head .nk-sidebar-brand .logo_image{
    width: 180px;
  }
  .btn-zoom::before{
    background-color: unset !important;
    box-shadow: unset !important;
  }
  .menu__icons{
    display: flex;
  }
  .menu__icons img{
    width: 10px;
  }
  .nk-footer-wrap{
    display: flex !important;
    justify-content: center !important;
  }
  
  
  
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .menu-card .menu-image{
height: 70px;
  }
  .menu-card{
    display: flex;
  }
  .menu-rows {
    display: flex;
    flex-wrap: wrap;
  }
  .category-item button{
    border: none;
    background-color: white;
    display: flex;
    align-items: center;
  }
  .category-item svg{
    color: #f9c623;
  }
  .menu-row {
    flex: 1;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 10px; 
  }
  
  @media (min-width: 768px) {
   
    .menu-row {
      flex: 1 1 48%; 
    }
  }
  
  @media (min-width: 992px) {
    .menu-row {
      flex: 1 1 31%; 
    }
  }
  
  @media (min-width: 1200px) {
    
    .menu-row {
      flex: 0 0 20%; 
      min-height: 250px;
    }
  }
  
  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
    .card-container {
        grid-template-columns: 1fr; 
      }
  }
  

.orderdetails-details{
    display: flex;
    /* align-items: center; */
    gap: 20px;
    height: 450px;
    
}

.orderdetails-details-side{
    width:550px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.orderdetails-items-container{
    width:96%;
    margin: 5px auto;
    

}
.addon-cl span{
    
}
.orderdetails-item{
    width: 100%;
    border: solid 0.5px black;
    border-radius: 10px;
    margin: 8px auto;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.orderdetails-item p{
    margin: 0px;
    padding: 0px;
}

.vertcal-line{
    height: 80%;
    border-left: solid 1px black ;
}

.orderdetails-bill-side{
    /* height: 100%; */
    width: 300px;
    /* height: 200px; */
    padding: 10Px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bill-price-item{
    margin: 0px;
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.bill-price-amount{
    
}
.address-item{
    font-size: 13px;
    margin-bottom: 6px;
}